import gsap from 'gsap'

export class Burger {
    tls_children = [];

    constructor() {
        this.banner = document.querySelector('.header .container')
        this.nav = document.querySelector('.header-primary-navigation--mobile')

        this.tl = gsap.timeline({paused: true});
        this.animation()

        const burger = document.querySelector('#burger')
        if (burger) {
            burger.addEventListener('click', evt => {
                evt.preventDefault()
                burger.classList.toggle('active')
                this.banner.classList.toggle('expanded')

                if (this.banner.classList.contains('expanded')) {
                    this.tl.play()
                } else {
                    this.tl.reverse()
                    for (const tl of this.tls_children) {
                        tl.reverse()
                    }
                }
            })
        }


        if (this.nav) {
            this.nav.querySelectorAll('.menu-item.menu-item-has-children > a').forEach(item => {
                item.addEventListener('click', evt => {

                    if (!evt.target.classList.contains('clicked')) {
                        evt.preventDefault()
                        evt.target.classList.add('clicked')
                    } else {
                        evt.target.classList.remove('clicked')
                    }

                    if (!item.parentNode.classList.contains('active')) {
                        this.nav.querySelectorAll('.menu-item.menu-item-has-children > a').forEach(el => {
                            el.parentNode.classList.remove('active')
                        })
                        item.parentNode.classList.add('active')
                    } else {
                        item.parentNode.classList.remove('active')
                    }
                })
            })
        }

    }

    animation() {
        this.tl.to(this.nav, {x: 0})
    }
}
