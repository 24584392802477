import {Datepicker} from 'vanillajs-datepicker';
import fr from 'vanillajs-datepicker/locales/fr';


export class DateInput {
	constructor() {
		this.input = document.querySelector('input[name="dateStart"]')

		if (this.input) {
			this.init();
		}
	}

	init() {
		Object.assign(Datepicker.locales, fr)

		const datePicker = new Datepicker(this.input, {
			language: 'fr',
			format: 'yyyy-mm',
			startView: "months",
			minViewMode: "months",
			pickLevel: 1
		})
	}
}
