import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export class Parallax {
	constructor()
	{
		const parallaxItems = document.querySelectorAll('[data-scroll-speed]');
		const p = 100;

		if (parallaxItems) {
			this.init(parallaxItems, p)
		}
	}

	init(parallaxItems, p)
	{
		parallaxItems.forEach(item => {
			let speed = item.getAttribute('data-scroll-speed')

			if (window.matchMedia("(max-width: 700px)").matches) {
				speed = speed * 0.2
			}

			const tl = gsap.timeline({
				scrollTrigger: {
					trigger: item,
					scrub: true,
				}
			})

			tl.set(item, {y: p})
			tl.to(item, {y: p * -speed, ease: "power1.out"})
		});
	}
}
