import '@css/main.scss';
import '@js/bootstrap';
import {Sliders} from "@js/utils/Sliders";
import {Parallax} from "@js/utils/Parallax";
import {Header} from "@js/utils/Header";
import {Archive} from "@js/utils/Archive";
import {Lightbox} from "@js/utils/Lightbox";
import {Burger} from "@js/utils/Burger";
import {FloatingBar} from "@js/utils/FloatingBar";
import {DateInput} from "@js/utils/DateInput";


window.onload = () => {
	new Sliders();
	new Parallax();
	new Header();
	new Archive();
	new Lightbox();
	new Burger();
	new FloatingBar();
	new DateInput();
}
