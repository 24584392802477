export class Header {
	constructor() {
		this.header = document.querySelector('.header');
		this.image = document.querySelector('.header-primary__brand .c-image-default');
		this.imageScroll = document.querySelector('.header-primary__brand .c-image-scroll');

		if (this.header) {
			this.init();
		}
	}

	init() {

		this.hero = document.querySelector('.s-hero');

		if (!this.hero) {
			this.header.classList.add('header--scrolled');
			this.imageScroll.removeAttribute('hidden');
			this.image.setAttribute('hidden', 'hidden');
		}

		window.addEventListener('scroll', () => {

			if (window.scrollY > 50) {
				this.header.classList.add('header--scrolled');
				this.imageScroll.removeAttribute('hidden');
				this.image.setAttribute('hidden', 'hidden');
			} else if (window.scrollY < 50 && this.hero){
				this.header.classList.remove('header--scrolled');
				this.imageScroll.setAttribute('hidden', 'hidden');
				this.image.removeAttribute('hidden');
			}
		});
	}
}
