export class Archive {
    constructor() {
        this.container = document.querySelector('*[data-archive]')
        if (!this.container) {
            return;
        }


        this.type = this.container.getAttribute('data-archive')
        this.itemsContainer = this.container.querySelector('*[data-archive-endpoint]')
        this.pagination = this.container.querySelector('[data-pagination]')
        this.filters_container_check = document.querySelector('*[data-filters-check]');
        this.filterreset = this.filters_container_check.querySelectorAll('*[data-filter="*"]');
        this.filters = this.filters_container_check.querySelectorAll('*[data-filter]');
        this.form = this.container.querySelector('form')
        this.init()
    }

    init() {
        this.config = this.defaults();
        this.url = `${window.location.protocol}//${window.location.hostname}/wp-json/akyos/v1/${this.type}`
        this.register()
    }

    defaults() {
        return {
            itemsperpage: 9,
            itemsoffset: 1,
            currentPage: 1,
            noMoreItems: false,
            currentTax: false,
        }
    }

    register() {
        const paramsUrl = window.location.search;

        if (paramsUrl) {
            let urlSearchParams = new URLSearchParams(paramsUrl);
            this.config.currentTax = urlSearchParams.get('current')
        }

        if (this.form) {
            this.form.addEventListener('change', event => {
                event.preventDefault();

                this.config.currentPage = 1;
                this.fetchFilter(true, false)
            })


            this.form.querySelector('input[type="month"]').addEventListener('changeDate', () => {

                this.config.currentPage = 1;
                this.fetchFilter(true, false)
            })
        }

        this.filterreset.forEach(f => {
            f.addEventListener('click', (e) => {
                e.preventDefault();

                const id = f.dataset.filter
                this.reset()
                this.filters.forEach(flt => flt.checked = false)
                this.form.dispatch(new CustomEvent('change'))
            })
        })

        this.fetchFilter();
    }

    fetchFilter(reset = false, current = true) {
        const params = new URLSearchParams(new FormData(this.form));
        params.append('per_page', this.config.itemsperpage);
        params.append('page', this.config.currentPage);
        if (this.config.currentTax && current) {
            params.append('current', this.config.currentTax);
        }
        document.querySelector('.s-archive').classList.add('loading')
        document.querySelector('.loader-custom').style.display = 'flex'


        fetch(`${this.url}?${params.toString()}&orderby=date&order=desc`)
            .then((r) => r.json().then(r => {
                    if (reset) {
                        this.itemsContainer.innerHTML = "";
                        this.pagination.querySelector('.pagination-pages').innerHTML = "";
                    }

                    if (r.posts.length === 0) {
                        if (r.count > 0) {
                            this.config.currentPage++
                            this.fetchFilter(true)
                        } else {
                            this.pagination.setAttribute('hidden', 'hidden')
                        }
                    }

                    if (r.posts.length !== 0 || (r.posts.length === 0 && r.count === 0)) {
                        this.paginationFn(r)

                        document.querySelector('.s-archive').classList.remove('loading')
                        document.querySelector('.loader-custom').style.display = 'none';
                        this.appendItems(r.posts);
                    }
                })
                    .catch(r => {
                        console.log(r)
                    })
            )
    }

    getDate(dateStr) {
        var elements = dateStr.split(' ');

        var mois = {
            "jan": "01",
            "fev": "02",
            "mars": "03",
            "avr": "04",
            "mai": "05",
            "juin": "06",
            "jui": "07",
            "aout": "08",
            "sept": "09",
            "oct": "10",
            "nov": "11",
            "dec": "12"
        };

        var jour = elements[0];
        var moisNum = mois[elements[1].toLowerCase()];
        var annee = elements[2];

        return annee + '-' + moisNum + '-' + jour;
    }


    comparerDates = (a, b) => {

        if (a.date === null || b.date === null) return 0;

        // Convertir les dates en format standard pour comparaison
        var dateA = this.getDate(a.date);
        var dateB = this.getDate(b.date);

        if (dateA > dateB) return 1;
        if (dateA < dateB) return -1;

    }

    appendItems(items) {
        const template = document.querySelector('#archive-el');

        items.sort(this.comparerDates)

        items.forEach((item) => {
            let clone = template.content.cloneNode(true);

            this.itemEvent(clone, item);
        })
    }

    itemEvent(clone, item) {
        const title = clone.querySelector('.c-title');
        const thumb = clone.querySelector('.c-image');
        const link = clone.querySelector('a');
        const term = clone.querySelector('.thumb__term')
        const date = clone.querySelector('.c-card-news-date')

        const day = document.createElement('span')
        const month = document.createElement('span')
        const year = document.createElement('span')

        day.classList.add('c-card-news-date__date-day')
        month.classList.add('c-card-news-date__date-month')
        year.classList.add('c-card-news-date__date-year')

        if (item?.date) {
            let dateArray = item?.date.split(' ');

            day.innerHTML = dateArray[0]
            month.innerHTML = dateArray[1]
            year.innerHTML = dateArray[2]

            date.appendChild(day)
            date.appendChild(month)
            date.appendChild(year)
        }

        const thumbnail = document.createElement('img');
        if (item?.thumbnail) {
            thumbnail.src = item?.thumbnail;
            thumb.appendChild(thumbnail);
        }

        if (title) title.innerHTML = item?.title;
        if (link) link.href = item?.link;
        if (term) term.innerHTML = item?.term;

        this.itemsContainer.appendChild(clone);
    }

    paginationFn(r) {
        let res = r.count / this.config.itemsperpage


        if (res >= 1) {
            this.pagination.removeAttribute('hidden', 'hidden')

            for (let i = 0; i < res; i++) {
                this.page = document.createElement('div');
                this.page.classList.add('pagination-pages__item');
                this.page.setAttribute('data-page-number', i + 1)

                if (i + 1 === parseInt(this.config.currentPage)) {
                    this.page.classList.add('current');
                }

                this.page.innerHTML = i + 1;
                this.pagination.querySelector('.pagination-pages').appendChild(this.page);
            }

            this.pagination.querySelectorAll('.pagination-pages__item').forEach(item => {
                item.addEventListener('click', (el) => {
                    this.config.currentPage = el.target.getAttribute('data-page-number');
                    this.fetchFilter(true);
                });
            })

            if (this.config.currentPage <= 1) {
                this.pagination.querySelector('.pagination-prev').setAttribute('hidden', 'hidden')
            } else {
                this.pagination.querySelector('.pagination-prev').removeAttribute('hidden', 'hidden')

                if (!this.pagination.querySelector('.pagination-prev').classList.contains('event')) {
                    this.pagination.querySelector('.pagination-prev').addEventListener('click', () => {
                        this.config.currentPage--;
                        this.fetchFilter(true);
                    })
                    this.pagination.querySelector('.pagination-prev').classList.add('event')
                }
            }

            if (this.config.currentPage >= res) {
                this.pagination.querySelector('.pagination-next').setAttribute('hidden', 'hidden')

            } else {
                this.pagination.querySelector('.pagination-next').removeAttribute('hidden', 'hidden')

                if (!this.pagination.querySelector('.pagination-next').classList.contains('event')) {
                    this.pagination.querySelector('.pagination-next').addEventListener('click', () => {
                        this.config.currentPage++;
                        this.fetchFilter(true);
                    })
                    this.pagination.querySelector('.pagination-next').classList.add('event')
                }
            }
        } else {
            this.pagination.setAttribute('hidden', 'hidden')
        }
    }

}
